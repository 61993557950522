import { useMemo } from 'react';

const useHeadPrices = (landingData) => {
	// Use useMemo to memoize the computed values for performance
	return useMemo(() => {
		// объект с name "head_price"
		const headPriceObject = landingData?.head?.find(
			(item) => item.name === 'head_price'
		);
		const headPriceValue = headPriceObject ? headPriceObject.value : null;

		// объект с name "head_price_crossline"
		const headPriceCrosslineObject = landingData?.head?.find(
			(item) => item.name === 'head_price_crossline'
		);
		const headPriceCrosslineValue = headPriceCrosslineObject
			? headPriceCrosslineObject.value
			: null;

		// объект с name "subsequent_price"
		const subsequentPriceObject = landingData?.head?.find(
			(item) => item.name === 'subsequent_price'
		);
		const subsequentPriceValue = subsequentPriceObject
			? subsequentPriceObject.value
			: null;

		return {
			headPriceValue,
			headPriceCrosslineValue,
			subsequentPriceValue,
		};
	}, [landingData]);
};

export default useHeadPrices;