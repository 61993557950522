import problem1 from '../../assets/images/stelki/problems/problem1.jpg';
import problem2 from '../../assets/images/stelki/problems/problem2.jpg';
import problem3 from '../../assets/images/stelki/problems/problem3.jpg';
import problem4 from '../../assets/images/stelki/problems/problem4.jpg';
import problem5 from '../../assets/images/stelki/problems/problem5.jpg';
import problem6 from '../../assets/images/stelki/problems/problem6.jpg';
import problem7 from '../../assets/images/stelki/problems/problem7.jpg';
import problem8 from '../../assets/images/stelki/problems/problem8.jpg';
import problem9 from '../../assets/images/stelki/problems/problem9.jpg';
import problem10 from '../../assets/images/stelki/problems/problem10.jpg';
import problem11 from '../../assets/images/stelki/problems/problem11.jpg';
import problem12 from '../../assets/images/stelki/problems/problem12.jpg';
import problem13 from '../../assets/images/stelki/problems/problem13.jpg';
import problem14 from '../../assets/images/stelki/problems/problem14.jpg';
import problem15 from '../../assets/images/stelki/problems/problem15.jpg';
import problem16 from '../../assets/images/stelki/problems/problem16.jpg';
import problem17 from '../../assets/images/stelki/problems/problem17.jpg';

export const images = [
	{
		id: 1,
		img: problem1,
		title: 'Плоскостопие',
		description: `Плоскостопие – известная болезнь, связанная с деформацией человеческой стопы. Патологическое состояние оказывает негативное воздействие на самочувствие и со временем может вызвать появление болей в области поясницы и позвоночника, формирование неправильной осанки, развитие сколиоза, артроза и варикоза.`,
	},
	{
		id: 2,
		img: problem2,
		title: 'Варикоз и отёки',
		description: `Образование варикозного расширения вен и отёков – последствие сидячего образа жизни, чрезмерной физической нагрузкой, наследственной предрасположенностью и ряд других факторов, что делает возможным его появление у людей различных возрастов и не зависит от пола. Игнорирование варикоза при его первоначальных проявлениях приводит к его более тяжелым формам и осложнениям. Последствиями могут быть тромбофлебит, кровотечения, нарушения питания тканей, повышенная пигментация, чрезмерное тромбообразование.`,
	},
	{
		id: 3,
		img: problem3,
		title: 'Грибок ногтя и стопы',
		description: `Микоз – это грибковое заболевание, которое поражает кожные покровы, внутренние органы и костные ткани (ногти и т. д.). Сопровождается этот недуг непрерывным зудом, шелушением и неприятным запахом. Грибковые инфекции крайне заразны и могут быстро распространиться на другие части тела, и заболевание может принять более серьезную форму. Впоследствии человек при затяжном игнорировании заболевания может заразить своих близких.`,
	},
	{
		id: 4,
		img: problem4,
		title: 'Вальгусная и варусная стопа',
		description: `Варусная деформация стоп — поворот стопы внутрь, при этом искривляется купол и ось стопы, а ноги в области голени образуют круг. Вальгусная же деформация подразумевает поворот стопы наружу. Ноги в области голени больного расходятся в стороны, поэтому такой вид деформации называется Х-образным. Последствиями варусной и вальгусной стопы становятся неуверенная походка с постоянными болями, ухудшение подвижности суставов и сосудов голени, состояния мышц, хрящей, связок обеих конечностей.`,
	},
	{
		id: 5,
		img: problem5,
		title: 'Диабетическая стопа',
		description: `Синдром диабетической стопы — это опасное осложнение сахарного диабета. При длительном пренебрежении лечения оно способно привести к образованию язвенного поражения тканей стопы, нередко выливающегося в гангрену ноги, которая впоследствии может привести к ампутации.`,
	},
	{
		id: 6,
		img: problem6,
		title: 'Беременность',
		description: `Беременность — это прекрасная пора для любой женщины, ведь она является важным этапом в свершении чуда- рождение нового человека. Однако беременность помимо радости от скоро рождения своего ребенка предполагает наличие таких побочных эффектов, как повышенная нагрузка на ноги, постоянная усталость, судороги, чрезмерная потливость и многое другое.`,
	},
	{
		id: 7,
		img: problem7,
		title: 'Заболевания позвоночника',
		description: `Искривления позвоночного столба, возникающий вследствие длительного неправильного сидячего положения и сутулости во время работы или учебы. Протрузии и межпозвоночные грыжи — это выпячивание или выход межпозвоночного диска за пределы позвонков в область спинномозгового канала. Согласно статистике, свыше 80% населения земного шара жалуются на боли в спине. Причиной дискомфортных ощущений могут быть серьезные заболевания позвоночника, которые значительно осложняют жизнь страдающему от них человеку. Откладывать посещение специалиста в случае, когда вы почувствовали боли, – неразумно.`,
	},
	{
		id: 8,
		img: problem8,
		title: 'Высокий свод стопы',
		description: `Высокий свод стопы или «полая стопа» — это болезнь, подразумевающая деформацию нижних конечностей. Это выражается в неправильном давлении на стопу, из-за чего происходит перегрузка пятки. Если вовремя не заняться лечением этой проблемы, могут возникнуть периодические растяжения связочных элементов голеностопа, подошвенный фасциит, неустойчивость во время ходьбы и бега.`,
	},
	{
		id: 9,
		img: problem9,
		title: 'Молоткообразные пальцы',
		description: `Молоткообразная деформация пальцев стопы — это изменение положения и формы суставов. Причинами возникновения данного недуга могут быть тесная обувь, врожденная патология или негативные изменения в суставах и связках. Если болезнь находится на запущенной стадии и пальцы не разгибаются и не могут занимать правильное положение, то в этом случае невозможно избежать оперативного вмешательства врачей для удаления части кости.`,
	},
	{
		id: 10,
		img: problem10,
		title: 'Пяточная шпора',
		description: `Пяточная шпора – это разрастание костной ткани в виде шипа в зоне бугра кости пятки или около ахиллова сухожилия. Этот недуг можно распознать по сильным болезненным ощущениям в пятке, особенно по утрам. Последствиями длительного пренебрежения лечения являются повреждение нервных волокон, снижение подвижности, откалывание остеофита, повышается вероятность развития различных травм, изменения походки и осанки.`,
	},
	{
		id: 11,
		img: problem11,
		title: 'Натоптыши и мозоли',
		description: `Натоптыши и мозоли — это уплотненная ороговевшая кожа на подушечках пальцев и между пальцами, на подошвах и пятках, которая появляется в результате постоянной неравномерной нагрузки на ногу, ношения узкой и тесной обуви с наличием неудобной колодки или высоких каблуков. Самым страшным последствием натоптышей могу быть трещины на стопах. Мозоли же редко приводят к каким-либо осложнениям. Единственное, в чем нужно проявлять осторожность при борьбе с мозолями — это аккуратность в их срезании, чтобы не допустить попадании инфекции.`,
	},
	{
		id: 12,
		img: problem12,
		title: 'Формирование стопы у детей',
		description: `Формирование стопы ребенка начинается еще в эмбриональном периоде. Стопа изменяется с начала 8 недели до 5-6 лет. В это время весь опорно-двигательный аппарат продолжает расти и, следовательно, меняться. Примерно с 3-летнего возраста, свой окончательный вид принимают кости и связки стоп. В этот период стоит внимательно подойти к делу и следить за формированием стопы ребенка, чтобы в дальнейшем стопа смогла полноценно функционировать. Важно помнить, что до 5-6 лет плоскостопие еще можно вылечить или скорректировать в более легкую форму.`,
	},
	{
		id: 13,
		img: problem13,
		title: 'Длительная ходьба или работа на ногах',
		description: `Ежедневно нам приходится проходить огромные расстояния в неудобной обуви на работе, а после тяжелого трудового дня уже просто не остается сил даже на себя. Если вы проводите много времени на ногах, то у вас может ухудшаться кровоснабжение нижних конечностей, что приводит к болезненным ощущениям. Также при долгом стоянии на ногах может происходить скопление крови в ступнях или вокруг щиколоток, плоскостопие, подошвенный фасцит, мозоли, отечность, варикозное расширение вен и венозная недостаточность. Ходьба – несомненно полезна, однако не для всех. Люди, имеющие проблемы с опорно-двигательным аппаратом страдают от боли в стопах больше, чем люди, не имеющие таких проблем. Ортопедические стельки способны облегчить нагрузку на стопы и тем самым продлить время прогулки.`,
	},
	{
		id: 14,
		img: problem14,
		title: 'Для городов с асфальтным покрытием',
		description: `Стопа человека изначально не была предназначена для ходьбы по гладкой, плоской поверхности. Стопам для развития необходима неровная дорога, но для жителей большого города это становится препятствием. В результате постоянной ходьбы по ровной поверхности стопа может атрофироваться и перестает в полном объеме функционировать в должной мере.`,
	},
	{
		id: 15,
		img: problem15,
		title: 'Разная длина ног',
		description: `Минимальные различия в длине ног не требуют особого лечения или использования ортопедических изделий, в то время как стоит обратить внимание на заметные различия. При разнице в длине ног до 2 см, рекомендуется использование ортопедических стелек для коррекции и фиксации стопы в правильном положении.`,
	},
	{
		id: 16,
		img: problem16,
		title: 'Лишний вес',
		description: `Лишний вес увеличивает нагрузку на стопы. Ходьба может доставлять немалый дискомфорт человеку с лишним весом ввиду того, что стопы не могут быстро адаптироваться к изменениям в нагрузке.`,
	},
	{
		id: 17,
		img: problem17,
		title: 'Косточка',
		description: `Косточка на ноге называется вальгусной деформацией большого пальца стопы. Латинское название Hallus valgus отражает суть происходящих изменений. Термином Hallus медики называют большой палец ноги. Valgus означает «кривой». Шишка образуется в результате длительного нарушения биомеханики первого плюснефалангового сустава. Большая нагрузка на сочленение постепенно вызывает смещение плюсневой кости большого пальца ноги наружу относительно средней линии тела. При этом первая фаланга отклоняется в сторону стопы. Из-за изменения положения костей перераспределяется нагрузка на стопу. Давление на плюснефаланговый сустав большого пальца увеличивается. Головка кости частично выходит из него, провоцируя дальнейшую деформацию стопы.`,
	},
];


