import slide1 from '../../../../assets/images/sockliners-slider/sport/slide1.jpg';
import slide2 from '../../../../assets/images/sockliners-slider/sport/slide2.jpg';
import slide3 from '../../../../assets/images/sockliners-slider/sport/slide3.jpg';
import slide4 from '../../../../assets/images/sockliners-slider/sport/slide4.jpg';
import slide5 from '../../../../assets/images/sockliners-slider/sport/slide5.jpg';
import slide6 from '../../../../assets/images/sockliners-slider/sport/slide6.jpg';
import slide7 from '../../../../assets/images/sockliners-slider/sport/slide7.jpg';
import slide8 from '../../../../assets/images/sockliners-slider/sport/slide8.jpg';
import slide9 from '../../../../assets/images/sockliners-slider/sport/slide9.jpg';
import color1 from '../../../../assets/images/sockliners-slider/sport/color1.jpg';
import color2 from '../../../../assets/images/sockliners-slider/sport/color2.jpg';
import color3 from '../../../../assets/images/sockliners-slider/sport/color3.jpg';
import color4 from '../../../../assets/images/sockliners-slider/sport/color4.jpg';
import color5 from '../../../../assets/images/sockliners-slider/sport/color5.jpg';
import color6 from '../../../../assets/images/sockliners-slider/sport/color6.jpg';

export const imagesMain = [
	slide1,
	slide2,
	slide3,
	slide4,
	slide5,
	slide6,
	slide7,
	slide8,
	slide9,
];

export const imagesColor = [color1, color2, color3, color4, color5, color6];
