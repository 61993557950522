import React from 'react';
import Header from '../../components/Header/Header.jsx';
import ErrorComponent from '../../components/ErrorComponent/ErrorComponent.jsx';
import Footer from '../../components/Footer/Footer.jsx';

export default function NotFound({ landingData }) {
	return (
		<>
			<Header landingData={landingData} />
			<ErrorComponent />
			<Footer landingData={landingData} />
		</>
	);
}
