import React from 'react';
import style from './index.module.scss';
import Comment from './Comment/Comment.jsx';

export default function Comments({ landingData }) {
	const [index, setIndex] = React.useState(0); // хук активного индекса комментария

	// кнопка вперед
	const increment = () => {
		if (index < landingData.review.length - 1) {
			setIndex(prev => prev + 1);
		} else {
			setIndex(0);
		}
	};
	// кнопка назад
	const decrement = () => {
		if (index <= 0) {
			setIndex(landingData.review.length - 1);
		} else {
			setIndex(prev => prev - 1);
		}
	};

	return (
		<section className={style.comments}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>Отзывы счастливых клиентов</h2>
				</div>
				<div className={style.slider}>
					<div className={style.btnPrev} onClick={decrement}></div>
					<Comment review={landingData.review[index]} />
					<div className={style.btnNext} onClick={increment}></div>
				</div>
			</div>
		</section>
	);
}
