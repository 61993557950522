/* eslint-disable no-undef */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import InputMask from 'react-input-mask';
import quizData from './helpers/quizData.json';
import { getUnMaskPhone } from '../../utils/maskPhone';
import { getQuizDataParsing } from '../../utils/quizDataParsing.js';

export default function Quiz({ landingData }) {
	let yandex;

	try {
		yandex = landingData?.metrika[0]?.yandex;
	} catch {
		yandex = '';
	}

	const navigation = useNavigate();
	const [info, setInfo] = React.useState({ 'Выберете проблему:': [] }); // стейт выбора ответов пользователя
	const [questions, setQuestions] = React.useState(quizData); // стейт вопросов
	const [value, setValue] = React.useState(0); // индекс активного вопроса
	const [isSelected, setIsSelected] = React.useState(''); // стейт для проверки выбран ли ответ
	const [control, setControl] = React.useState(false); // стейт для проверки прохождения всей длины массива вопросов

	// кнопка вперед
	const increment = () => {
		if (value < questions.length - 1 && isSelected) {
			setValue(prev => prev + 1);
			setIsSelected(''); // очищаем стейт
		} else if (isSelected) {
			setControl(true);
		}
	};

	// кнопка назад
	const decrement = () => {
		if (value > 0) {
			setValue(prev => prev - 1);
			setIsSelected(''); // очищаем стейт
		}
	};

	//проверка radio button
	const handleChange = event => {
		setIsSelected(event.target.value);
	};

	// получаем данные в стейт в зависимости от разных вариаций выбора пользователя
	const getInfo = event => {
		setIsSelected(event.target.value);
		if (event.target.value === 'Для обычной ходьбы') {
			setQuestions(quizData.filter(el => el.branch === 0 || el.branch === 1));
		}
		if (event.target.value === 'Для бега') {
			setQuestions(quizData.filter(el => el.branch === 0 || el.branch === 2));
		}
		if (event.target.value === 'Для лечения') {
			setQuestions(quizData.filter(el => el.branch === 0 || el.branch === 3));
		}

		if (event.target.name === 'Выберете проблему:') {
			setInfo(prev => ({
				...prev,
				'Выберете проблему:': [
					...prev['Выберете проблему:'],
					event.target.value,
				],
			}));
		} else {
			setInfo(prev => ({ ...prev, [event.target.name]: event.target.value }));
		}
	};
	const getUserPhone = event => {
		event.preventDefault();
		const phone = event.target.phone.value;
		const newPhone = getUnMaskPhone(phone);
		let notes = getQuizDataParsing(info);

		if (phone) {
			let userDataQuiz = {
				name: 'QUIZ',
				phone: newPhone,
				department: landingData.department,
				status: 1,
				notes,
				city: landingData.city,
				email: '',
				url: window.location.href,
			};

			fetch(
				'https://onshorkin.com/i8fd90sd/v09cxvjksdf/xzs9/sda9/service_request/',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json;charset=utf-8',
					},
					body: JSON.stringify(userDataQuiz),
				}
			)
				.then(response => response.json())
				.then(data => {
					console.log(data);
					ym(yandex, 'reachGoal', 'SubmittingSurveyForm');
					_tmr.push({
						type: 'reachGoal',
						id: 3314671,
						value: 900,
						goal: 'otpravkakviz',
					});
				})
				.catch(e => {
					console.log(e);
					navigation('/error');
				});
			navigation('/sent');
		}
	};

	return (
		<section className={style.quiz}>
			<div className={style.containerForm}>
				<div className={style.inner}>
					<div className={style.quizTitle}>
						<div className={style.title}>
							Как правильно подобрать стельки
							<div className={style.line}></div>
						</div>
					</div>
				</div>

				{control ? (
					<form onSubmit={getUserPhone} className={style.step}>
						<div className={style.final}>
							<div className={style.title}>
								Заполните форму и получите результаты, какие стельки подойдут
								именно Вам:{')'}
							</div>
							<div className={style.phone}>
								<InputMask
									className={style.input}
									mask='+7 (999) 999-99-99'
									type='tel'
									name='phone'
									id='phone'
									placeholder='Ваш номер телефона'
									required
								/>
							</div>
							<button className={style.button}>Получить результаты</button>
						</div>
					</form>
				) : (
					<>
						<form onChange={getInfo} className={style.slider}>
							<div className={style.step}>
								<div className={style.container}>
									<div className={style.question}>
										{questions[value].question}
									</div>
									<div className={style.remark}>{questions[value].remark}</div>
									{questions[value].answers.map(answer => (
										<div className={style.answer} key={answer.id}>
											<label className={style.label}>
												{questions[value].type === 'radio' ? (
													<input
														className={style.input}
														name={questions[value].question}
														value={answer.answerText}
														type={questions[value].type}
														checked={isSelected === answer.answerText}
														onChange={handleChange}
													/>
												) : (
													<input
														className={style.input}
														name={questions[value].question}
														value={answer.answerText}
														type={questions[value].type}
													/>
												)}

												<span className={style.span}>{answer.answerText}</span>
											</label>
										</div>
									))}
								</div>
							</div>
							<div className={style.innerControl}>
								<div className={style.controls}>
									<button
										type='reset'
										className={style.btnPrev}
										onClick={decrement}
									>
										Назад
									</button>
									<div className={style.status}>
										Вопрос
										<span className={style.current}> {value + 1} </span>
										из
										<span className={style.total}>
											{' '}
											{questions.length > 6 ? 5 : questions.length}{' '}
										</span>
									</div>
									<button
										type='reset'
										className={isSelected ? style.btnNextActive : style.btnNext}
										onClick={increment}
									>
										Далее
									</button>
								</div>
								<div className={style.progress}>
									<div
										className={style.progressBar}
										style={{ width: `${(value / questions.length) * 100}%` }}
									></div>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</section>
	);
}
