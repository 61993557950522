import React from 'react';
import style from './index.module.scss';

export default function Advantages() {
	return (
		<section className={style.stelki}>
			<div className={style.inner}>
				<div className={style.titleBox}>
					<h2 className={style.title}>Есть решение!</h2>
				</div>
				<div className={style.blocks}>
					<div className={style.block}>
						<div className={style.blockTitle}>Пяточный амортизатор</div>
						<div className={style.blockSubtitle}>
							Коррекция деформации пятки выполняется
							<br /> под воздействием качественного амортизатора
						</div>
						<ul className={style.blockList}>
							<li className={style.blockItem}>
								Снижает утомляемость ног во время беременности
							</li>
							<li className={style.blockItem}>
								Снижает утомляемость при длительной ходьбе
							</li>
							<li className={style.blockItem}>Устраняет боль в спине</li>
							<li className={style.blockItem}>Исправляет плоскостопие</li>
							<li className={style.blockItem}>Снимает варикоз и отеки</li>
						</ul>
						<div aria-hidden='true' className={style.line}></div>
					</div>
					<div className={style.block}>
						<div className={style.blockTitle}>Специальный каркас стельки</div>
						<div className={style.blockSubtitle}>
							Снижает нагрузку на стопу и компенсирует
							<br /> недостаточную амортизацию продольного свода
						</div>
						<ul className={style.blockList}>
							<li className={style.blockItem}>
								Снижает степень искривления позвоночника
							</li>
							<li className={style.blockItem}>Исправляет высокий свод стопы</li>
							<li className={style.blockItem}>Снимает варикоз и отеки</li>
							<li className={style.blockItem}>
								Исправляет вальгусную или варусную стопу
							</li>
							<li className={style.blockItem}>
								Устраняет проблемы молоткообразных пальцев
							</li>
							<li className={style.blockItem}>
								Снижает нагрузку на коленные суставы
							</li>
							<li className={style.blockItem}>Устраняет боль в спине</li>
						</ul>
						<div aria-hidden='true' className={style.line}></div>
					</div>
					<div className={style.block}>
						<div className={style.blockTitle}>Дышащие материалы</div>
						<div className={style.blockSubtitle}>
							Натуральная кожа, вспененный латекс, насыщенные противогрибковыми
							компонентами не позволяют преть коже стопы
						</div>
						<ul className={style.blockList}>
							<li className={style.blockItem}>
								Отсутствие вероятности заражения грибком
							</li>
							<li className={style.blockItem}>Устраняет неприятные запахи</li>
							<li className={style.blockItem}>
								Способствует лечению диабетической стопы
							</li>
							<li className={style.blockItem}>Устраняет натоптыши и мозоли</li>
						</ul>
						<div aria-hidden='true' className={style.line}></div>
					</div>
					<div className={style.block}>
						<div className={style.blockTitle}>Нижнее покрытие</div>
						<div className={style.blockSubtitle}>
							Это износостойкий текстиль, <br /> который обладает высокой
							прочностью <br /> и препятствует скольжению стельки
						</div>
						<ul className={style.blockList}>
							<li className={style.blockItem}>
								Способствует лечению пяточной шпоры
							</li>
							<li className={style.blockItem}>
								Устраняет проблемы молоткообразных пальцев
							</li>
						</ul>
						<div aria-hidden='true' className={style.line}></div>
					</div>
				</div>
			</div>
		</section>
	);
}
