import React from 'react';
import style from './index.module.scss';
import Sport from './SliderItems/Sport/Sport.jsx';
import Classic from './SliderItems/Classic/Classic.jsx';
import SemiInsoles from './SliderItems/SemiInsoles/SemiInsoles.jsx';
import Winter from './SliderItems/Winter/Winter.jsx';

export default function OrderSlider({ landingData, toggleModal }) {
	const [activeSlide, setActiveSlide] = React.useState(0); // индекс активного слайда

	// получаем индекс
	const handleSlide = e => {
		setActiveSlide(e);
	};

	return (
		<section className={style.orderSlider}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>
						Выберите стельки, подходящие именно Вам
					</h2>
					<div className={style.line}></div>
				</div>
				<div className={style.switchers}>
					<div
						onClick={() => handleSlide(0)}
						className={
							activeSlide === 0 ? style.switcherActive : style.switcher
						}
					>
						Спортивные
					</div>
					<div
						onClick={() => handleSlide(1)}
						className={
							activeSlide === 1 ? style.switcherActive : style.switcher
						}
					>
						Классические
					</div>
					<div
						onClick={() => handleSlide(2)}
						className={
							activeSlide === 2 ? style.switcherActive : style.switcher
						}
					>
						Полустельки
					</div>
					<div
						onClick={() => handleSlide(3)}
						className={
							activeSlide === 3 ? style.switcherActive : style.switcher
						}
					>
						Зимние
					</div>
				</div>

				<div className={style.contentWrapper}>
					{activeSlide === 0 && (
						<Sport landingData={landingData} toggleModal={toggleModal} />
					)}
					{activeSlide === 1 && (
						<Classic landingData={landingData} toggleModal={toggleModal} />
					)}
					{activeSlide === 2 && (
						<SemiInsoles landingData={landingData} toggleModal={toggleModal} />
					)}
					{activeSlide === 3 && (
						<Winter landingData={landingData} toggleModal={toggleModal} />
					)}
				</div>
			</div>
		</section>
	);
}
