import React from 'react';
import style from './index.module.scss';

export default function Comment({ review }) {
	return (
		<div className={style.slide}>
			<div className={style.testimonial}>
				<div className={style.testimonialImg}>
					<img className={style.image} src={review.image} alt='' />
				</div>
				<div className={style.content}>
					<div className={style.title}>{review.head}</div>
					<div className={style.text}>
						<p>&quot;{review.description}&quot;</p>
					</div>
					<div className={style.footer}>
						<div className={style.author}>
							<div className={style.name}>{review.person_fullname}</div>
							<div className={style.status}>{review.person_info}</div>
						</div>
						<a
							href={review.social_url}
							className={review.social_url ? style.linkSocial : ''}
						>
							{' '}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
