import slide1 from '../../../../assets/images/sockliners-slider/half/slide1.jpg';
import slide2 from '../../../../assets/images/sockliners-slider/half/slide2.jpg';
import slide3 from '../../../../assets/images/sockliners-slider/half/slide3.jpg';
import slide4 from '../../../../assets/images/sockliners-slider/half/slide4.jpg';


import color1 from '../../../../assets/images/sockliners-slider/half/color1.jpg';


export const imagesMain = [slide1, slide2, slide3, slide4];

export const imagesColor = [color1];
