import React from 'react';
import style from './index.module.scss';

export default function Item({ sert, toggleModalSlider }) {
	return (
		<div onClick={() => toggleModalSlider(sert)} className={style.item}>
			<div className={style.image}>
				<img className={style.img} src={sert.img} alt='' />
			</div>
			<div className={style.label}>{sert.label}</div>
		</div>
	);
}
